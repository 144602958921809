import { Action } from '@ngrx/store';
import { isSameAction } from './is-same-action.function';

/**
 * @deprecated use isSameAction instead.
 * will be removed in next major release v16
 */
export function isAction(target: Action, matching: Action): boolean;

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isAction(target: any): boolean;
//eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isAction(target: Action | any, matching?: Action): boolean {
  if (matching) {
    return isSameAction(target, matching);
  }
  return isActionNew(target);
}

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isActionNew(action: any): action is Action {
  return typeof action !== 'function' && action && action.type && typeof action.type === 'string';
}
