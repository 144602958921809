import {
  ProductTypeContract,
  ProductTypeDetailedContract,
} from '@scheduler-frontend/data-access-product-types';

export function mustHaveLevel(
  productType: ProductTypeDetailedContract | ProductTypeContract | undefined,
): boolean {
  return !!(productType && 'requiresLevel' in productType && productType.requiresLevel);
}
