import { EnvironmentProviders, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { provideLocationsStore } from '@scheduler-frontend/data-access-locations';
import {
  AWAITING_FIRST_SLOTS_FEATURE_KEY,
  awaitingFirstSlotsReducer,
} from './+state/awaiting-first-slots.reducer';
import {
  AWAITING_FOLLOW_UP_SLOTS_FEATURE_KEY,
  awaitingFollowupSlotsReducer,
} from './+state/awaiting-follow-up-slots.reducer';
import { AwaitingFirstSlotsEffects } from './+state/effects/awaiting-first-slots.effects';
import { AwaitingFollowUpSlotsEffects } from './+state/effects/awaiting-follow-up-slots.effects';
import { AwaitingSlotsStoreService } from './awaiting-slots-store.service';

export function provideAwaitingSlotsStore(): Provider | EnvironmentProviders[] {
  return [
    provideLocationsStore(),
    provideState({ name: AWAITING_FIRST_SLOTS_FEATURE_KEY, reducer: awaitingFirstSlotsReducer }),
    provideState({
      name: AWAITING_FOLLOW_UP_SLOTS_FEATURE_KEY,
      reducer: awaitingFollowupSlotsReducer,
    }),
    provideEffects(AwaitingFirstSlotsEffects, AwaitingFollowUpSlotsEffects),
    AwaitingSlotsStoreService,
  ];
}
