import { ChangeDetectionStrategy, Component, effect, inject, input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { IonButton, IonInput, IonItem, IonNote } from '@ionic/angular/standalone';
import { timeRangeValidator } from '@techniek-team/common';
import { TtTimeInputControlComponent } from '@techniek-team/components/time-input';
import { formatISO, isValid, parseISO } from 'date-fns';
import { NgxControlError } from 'ngxtension/control-error';
import { filter } from 'rxjs';
import { SlotToCreateContract } from '../../../../contract/slot-to-create.contract';
import { CreateSlotsRequirementsStoreService } from '../../../create-slots-requirements-store.service';
import { SlotsToCreateStoreService } from '../../../slots-to-create-store.service';

@Component({
  selector: 'app-slot-to-create',
  standalone: true,
  imports: [
    FaIconComponent,
    NgxControlError,
    ReactiveFormsModule,
    TtTimeInputControlComponent,
    IonNote,
    IonItem,
    IonInput,
    IonButton,
  ],
  templateUrl: './slot-to-create.component.html',
  styleUrl: './slot-to-create.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlotToCreateComponent {
  protected readonly slotsToCreateStoreService = inject(SlotsToCreateStoreService);

  protected readonly requirementsStoreService = inject(CreateSlotsRequirementsStoreService);

  protected readonly faTrash = faTrash;

  public readonly slotToCreate = input.required<SlotToCreateContract>();

  public readonly gridRow = input.required<number>();

  protected readonly form = new FormGroup({
    timeRange: new FormGroup(
      {
        startTime: new FormControl<Date | null>(null, [Validators.required]),
        endTime: new FormControl<Date | null>(null, [Validators.required]),
      },
      [timeRangeValidator({})],
    ),
    amountOfPupils: new FormControl<number | null>(null),
  });

  protected readonly fillForm = effect(() => {
    const slotToCreate = this.slotToCreate();
    if (
      slotToCreate &&
      // Somehow `emitEvent: false` is not enough. When you remove this an infinite loop occurs.
      this.form.value.timeRange?.startTime === null &&
      this.form.value.timeRange?.endTime === null
    ) {
      this.form.reset(
        {
          timeRange: {
            startTime: parseISO(this.slotToCreate().timeRange.start),
            endTime: parseISO(this.slotToCreate().timeRange.end),
          },
          amountOfPupils: this.slotToCreate().amountOfPupils ?? null,
        },
        { emitEvent: false },
      );
    }
  });

  protected readonly setChangesToStore = this.form.valueChanges
    .pipe(
      takeUntilDestroyed(),
      filter(() => this.form.valid),
    )
    .subscribe((value) => {
      if (!isValid(value.timeRange?.startTime) || !isValid(value.timeRange?.endTime)) {
        return;
      }
      this.slotsToCreateStoreService.updateSlotToCreate(this.slotToCreate().id, {
        amountOfPupils: value.amountOfPupils ?? undefined,
        timeRange: value.timeRange
          ? {
              start: formatISO(value.timeRange.startTime as Date),
              end: formatISO(value.timeRange.endTime as Date),
              inclusiveStart: true,
              inclusiveEnd: false,
            }
          : undefined,
      });
    });

  protected removeSlotToCreate(): void {
    this.slotsToCreateStoreService.removeSlotToCreate(this.slotToCreate().id);
  }
}
