import { EnvironmentProviders, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { provideLocationsStore } from '@scheduler-frontend/data-access-locations';
import { provideRolesStore } from '@scheduler-frontend/data-access-roles';
import { provideSchedulesStore } from '@scheduler-frontend/data-access-schedules';
import { provideSlotsStore } from '@scheduler-frontend/data-access-slots';
import { provideSubjectsStore } from '@scheduler-frontend/data-access-subjects';
import { CreateSlotsEffects } from './+state/effects/create-slots.effects';
import { LoadBusinessServiceEffects } from './+state/effects/load-business-service.effects';
import { LoadLessonsEffects } from './+state/effects/load-lessons.effects';
import { LoadProductsByLocationEffects } from './+state/effects/load-products-by-location.effects';
import { LoadSchedulesEffects } from './+state/effects/load-schedules.effects';
import { OpenCreateSlotsEffects } from './+state/effects/open-create-slots.effects';
import {
  CREATE_SLOTS_FEATURE_KEY,
  createCreateSlotsReducer,
} from './+state/reducers/create-slots.reducer';
import {
  SLOTS_TO_CREATE_FEATURE_KEY,
  slotsToCreateReducer,
} from './+state/reducers/slots-to-create.reducer';
import { CreateSlotSetStoreService } from './create-slot-set-store.service';
import { CreateSlotsRequirementsStoreService } from './create-slots-requirements-store.service';
import { CreateSlotsSelectedStoreService } from './create-slots-selected-store.service';
import { CreateSlotsStoreService } from './create-slots-store.service';
import { SlotsToCreateStoreService } from './slots-to-create-store.service';

export function provideCreateSlotsStore(): Provider | EnvironmentProviders[] {
  return [
    provideSchedulesStore(),
    provideLocationsStore(),
    provideSubjectsStore(),
    provideRolesStore(),
    provideSlotsStore(),
    provideState({ name: CREATE_SLOTS_FEATURE_KEY, reducer: createCreateSlotsReducer }),
    provideState({ name: SLOTS_TO_CREATE_FEATURE_KEY, reducer: slotsToCreateReducer }),
    provideEffects(
      CreateSlotsEffects,
      OpenCreateSlotsEffects,
      LoadBusinessServiceEffects,
      LoadLessonsEffects,
      LoadProductsByLocationEffects,
      LoadSchedulesEffects,
    ),
    CreateSlotsStoreService,
    CreateSlotSetStoreService,
    CreateSlotsRequirementsStoreService,
    CreateSlotsSelectedStoreService,
    SlotsToCreateStoreService,
  ];
}
