import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  effect,
  ElementRef,
  forwardRef,
  inject,
  Injector,
  input,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import {
  IonInput,
  IonItem,
  IonLabel,
  IonNote,
  IonSelect,
  IonSelectOption,
} from '@ionic/angular/standalone';
import { BusinessServicesStoreService } from '@scheduler-frontend/data-access-business-services';
import { LocationContract } from '@scheduler-frontend/data-access-locations';
import { RoleContract, RoleDetailedContract } from '@scheduler-frontend/data-access-roles';
import { SubjectContract } from '@scheduler-frontend/data-access-subjects';
import { ChooseableLevelEnum, LevelDisplayValues, LevelEnum } from '@scheduler-frontend/enums';
import { fixFormControlMarkAs, getNestedErrors, timeRangeValidator } from '@techniek-team/common';
import {
  TtIonSelectSearchControlComponent,
  TtIonSelectSearchOptionDirective,
} from '@techniek-team/components/ion-select-search';
import { TtTimeInputControlComponent } from '@techniek-team/components/time-input';
import { isDefined } from '@techniek-team/rxjs';
import { JsonLdSelectIdPipe } from '@techniek-team/tt-ngrx';
import { TsRangeInterface } from '@techniek-team/tt-range';
import { formatISO } from 'date-fns';
import { NgxControlError } from 'ngxtension/control-error';
import { ifValidator } from 'ngxtension/if-validator';
import { distinctUntilChanged, filter } from 'rxjs';
import { CreateSlotSetStoreService } from '../../create-slot-set-store.service';
import { CreateSlotsRequirementsStoreService } from '../../create-slots-requirements-store.service';
import { CreateSlotsStoreService } from '../../create-slots-store.service';

export interface WhoForm {
  role: FormControl<RoleDetailedContract | null>;

  timeRange: FormGroup<{
    startTime: FormControl<Date | null>;
    endTime: FormControl<Date | null>;
  }>;

  amountOfPupils: FormControl<number | null>;

  operationalLocation: FormControl<LocationContract | null>;

  subject: FormControl<SubjectContract | null>;

  level: FormControl<LevelEnum | null>;
}

export interface WhoFormData {
  role: RoleDetailedContract | null;

  timeRange: {
    startTime: Date | null;
    endTime: Date | null;
  };

  amountOfPupils?: number | null;

  operationalLocation?: LocationContract | null;

  subject?: SubjectContract | null;

  level?: LevelEnum | null;
}

@Component({
  selector: 'app-who-control',
  templateUrl: './who-control.component.html',
  styleUrls: ['./who-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WhoControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => WhoControlComponent),
      multi: true,
    },
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TtIonSelectSearchControlComponent,
    TtTimeInputControlComponent,
    NgxControlError,
    ReactiveFormsModule,
    JsonLdSelectIdPipe,
    IonLabel,
    IonNote,
    IonItem,
    IonInput,
    IonSelect,
    IonSelectOption,
    TtIonSelectSearchOptionDirective,
  ],
})
export class WhoControlComponent implements ControlValueAccessor, Validator, AfterViewInit {
  private readonly elementRef = inject(ElementRef);

  private readonly injector = inject(Injector);

  private readonly changeDetectorRef = inject(ChangeDetectorRef);

  protected readonly createSlotsStoreService = inject(CreateSlotsStoreService);

  protected readonly businessServicesStoreService = inject(BusinessServicesStoreService);

  protected readonly setStoreService = inject(CreateSlotSetStoreService);

  protected readonly requirementsStoreService = inject(CreateSlotsRequirementsStoreService);

  protected readonly schoolLevels: ChooseableLevelEnum[] = [
    LevelEnum.PO,
    LevelEnum.VMBO_TL,
    LevelEnum.VMBO_B,
    LevelEnum.VMBO_K,
    LevelEnum.HAVO,
    LevelEnum.VWO,
    LevelEnum.HBO,
    LevelEnum.WO,
  ];

  protected readonly LevelDisplayValues: typeof LevelDisplayValues = LevelDisplayValues;

  public readonly rowNumber = input.required<number>();

  public readonly index = input.required<number>();

  protected readonly whoControl = new FormGroup<WhoForm>({
    role: new FormControl<RoleDetailedContract | null>(null, [Validators.required]),
    timeRange: new FormGroup(
      {
        startTime: new FormControl<Date | null>(null, [Validators.required]),
        endTime: new FormControl<Date | null>(null, Validators.required),
      },
      [timeRangeValidator({})],
    ),
    amountOfPupils: new FormControl<number | null>(
      null,
      ifValidator(
        () => this.requirementsStoreService.mustSetNumberOfPupils(),
        [Validators.required],
      ),
    ),
    operationalLocation: new FormControl<LocationContract | null>(null),
    subject: new FormControl<SubjectContract | null>(
      null,
      ifValidator(() => this.requirementsStoreService.mustHaveSubject(), [Validators.required]),
    ),
    level: new FormControl<LevelEnum | null>(
      null,
      ifValidator(() => this.requirementsStoreService.mustHaveLevel(), [Validators.required]),
    ),
  });

  protected displayByName = (
    item: RoleContract | RoleDetailedContract | LocationContract | SubjectContract,
  ) => item.name as string;

  private readonly updateFormOnSignalChange = effect(() => {
    this.requirementsStoreService.mustSetNumberOfPupils();
    this.requirementsStoreService.mustHaveLevel();
    this.requirementsStoreService.mustHaveSubject();
    this.whoControl.updateValueAndValidity();
  });

  protected disableRoleFormControlWhenNoRoles = effect(() => {
    const roles = this.createSlotsStoreService.validRoles();
    if (roles.length === 0) {
      this.whoControl.controls.role.disable();
    } else {
      this.whoControl.controls.role.enable();
    }

    this.changeDetectorRef.markForCheck();
  });

  private readonly toggleAmountOfPupilsFormControl = effect(() => {
    if (this.requirementsStoreService.mustSetNumberOfPupils()) {
      this.whoControl.controls.amountOfPupils.enable();
      return;
    }

    this.whoControl.controls.amountOfPupils.disable();
  });

  private readonly toggleLevelFormControl = effect(() => {
    if (this.requirementsStoreService.mustHaveLevel()) {
      this.whoControl.controls.level.enable();
      return;
    }

    this.whoControl.controls.level.disable();
  });

  private readonly toggleSubjectFormControl = effect(() => {
    if (
      this.requirementsStoreService.mustHaveSubject() ||
      this.createSlotsStoreService.validSubjects().length > 0
    ) {
      this.whoControl.controls.subject.enable();
      return;
    }

    this.whoControl.controls.subject.disable();
  });

  private readonly mayHaveDifferentOperationalLocationControl = effect(() => {
    if (
      this.requirementsStoreService.mayHaveDifferentOperationalLocation() ||
      this.requirementsStoreService.isAtHome()
    ) {
      this.whoControl.controls.operationalLocation.enable();
      return;
    }

    this.whoControl.controls.operationalLocation.disable();
  });

  protected setOperationalLocationToStore =
    this.whoControl.controls.operationalLocation.valueChanges
      .pipe(
        takeUntilDestroyed(),
        isDefined(),
        distinctUntilChanged((a, b) => a?.['@id'] === b?.['@id']),
      )
      .subscribe((value) => {
        this.setStoreService.changeWhoOperationalLocation(this.index(), value);
      });

  protected setRoleToStore = this.whoControl.controls.role.valueChanges
    .pipe(
      takeUntilDestroyed(),
      isDefined(),
      distinctUntilChanged((a, b) => a?.['@id'] === b?.['@id']),
    )
    .subscribe((value) => {
      this.setStoreService.changeWhoRole(this.index(), value);
    });

  protected setLevelToStore = this.whoControl.controls.level.valueChanges
    .pipe(takeUntilDestroyed(), isDefined(), distinctUntilChanged())
    .subscribe((value) => {
      this.setStoreService.changeWhoLevel(this.index(), value);
    });

  protected setSubjectToStore = this.whoControl.controls.subject.valueChanges
    .pipe(
      takeUntilDestroyed(),
      isDefined(),
      distinctUntilChanged((a, b) => a?.['@id'] === b?.['@id']),
    )
    .subscribe((value) => {
      this.setStoreService.changeWhoSubject(this.index(), value);
    });

  protected setAmountOfPupilsToStore = this.whoControl.controls.amountOfPupils.valueChanges
    .pipe(takeUntilDestroyed(), isDefined(), distinctUntilChanged())
    .subscribe((value) => {
      this.setStoreService.changeWhoAmountOfPupils(this.index(), value);
    });

  protected setTimeRangeToStore = this.whoControl.controls.timeRange.valueChanges
    .pipe(
      takeUntilDestroyed(),
      filter(() => this.whoControl.controls.timeRange.valid),
    )
    .subscribe((value) => {
      this.setStoreService.changeWhoTimeRange(this.index(), {
        start: formatISO(value.startTime as Date),
        end: formatISO(value.endTime as Date),
        inclusiveStart: true,
        inclusiveEnd: false,
      } as TsRangeInterface<string>);
    });

  public onTouch: () => void = () => {
    /* callback */
  };

  private onChange: (output: FormGroup<WhoForm>['value']) => void = (_output) => {
    /* callback */
  };

  private readonly onChangeSubscription = this.whoControl.valueChanges
    .pipe(takeUntilDestroyed())
    .subscribe((change) => {
      this.onChange(change);
    });

  /**
   * @inheritDoc
   */
  public ngAfterViewInit(): void {
    fixFormControlMarkAs(this.injector, this.whoControl, this.elementRef);
  }

  /**
   * @inheritDoc
   */
  public registerOnChange(fn: (output: FormGroup<WhoForm>['value']) => void): void {
    this.onChange = fn;
  }

  /**
   * @inheritDoc
   */
  public registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  /**
   * @inheritDoc
   */
  public validate(control: AbstractControl): ValidationErrors | null {
    if (this.whoControl.invalid) {
      return getNestedErrors(this.whoControl);
    }

    if (control.validator !== null) {
      return control.validator(this.whoControl);
    }

    return null;
  }

  /**
   * @inheritDoc
   */
  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.whoControl.disable();
    } else {
      this.whoControl.enable();
    }
  }

  /**
   * @inheritDoc
   */
  public writeValue(value: Partial<WhoFormData> | null): void {
    if (!value) {
      this.whoControl.reset();
      return;
    }

    this.whoControl.reset(value);
    this.whoControl.markAllAsTouched();
  }
}
