import {
  ProductTypeContract,
  ProductTypeDetailedContract,
} from '@scheduler-frontend/data-access-product-types';

export function mayHaveAlternativeLocation(
  productType: ProductTypeDetailedContract | ProductTypeContract | undefined,
): boolean {
  return !!(
    productType &&
    'allowAlternateLocation' in productType &&
    productType.allowAlternateLocation
  );
}
